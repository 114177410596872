import React from 'react';
// import { SearchOutlined } from '@ant-design/icons';
import {Button, Checkbox, Col, DatePicker, Form, Input, message, Radio, Row, Select, Modal,Table,Spin,Space,Icon } from 'antd';
import * as r from 'ramda';
import moment from 'moment';
import './newRegisterInfoRegion.scss'
import Api from 'api';
import saveAs from 'file-saver';
import JsZip from 'jszip'
import md5 from 'md5';
import { isRoot,isRegion, } from "src/context/NavItem";
import Util2  from 'src/util/util2';
import {
    formItemLayout,
    tailFormItemLayout
} from "src/context/project";
import cookie from "react-cookies";
import sm2 from 'sm-crypto';
// import Highlighter from 'react-highlight-words';


const sm2Cipher=sm2.sm2;
const publicKey = "0409db2be43f63522033164b626aba995f3e9233548590ee774bef832cbaf38983bddef48e502d082d8d7680c7c6e86072c7c432391912b3a4eae42b91b33a1443";
const cipherMode = 1

const {Option} = Select;
const confirm = Modal.confirm;
const regionJsonConverter = Util2.regionJsonConverter;

const regionCodeArr = Util2.regionCodeArr;
const buildType = Util2.projectTypeTransfer;
const CodeArr = Util2.CodeArr;
const sourceType = Util2.sourceType;
const sourceCodeArr = Util2.sourceCodeArr;
const warmType = Util2.warmType;
const windType = Util2.windType;
const buildGlassType = Util2.buildGlassType;
const frameType = Util2.frameType;
const heatType = Util2.heatType;
const airSysType = Util2.airSysType;
const checkType = Util2.checkType;
const stepsArr=['注册/无','准备硬件 申请账号','下发账号 建筑编码','数据接入 申请统计','检查状态 开通统计','7天试运行','下发证明，结束'];
const checkCodeArr = ['OK','ING'];

// export class DownloadUtil {
//
//     constructor() {
//         this.zip = new JsZip();
//     }
//     // 添加文件到zip
//     addFileInZip(fileName, content, option) {
//         this.zip.file(fileName, content, option)
//     }
//     // 添加文件夹到zip
//     addFolderInZip(folderName) {
//         return this.zip.folder(folderName)
//     }
//     // 打包成blob（二进制数据）
//     packageZip2blob() {
//         return this.zip.generateAsync({ type: 'blob' })
//     }
//     // 打包并下载(默认打包成blob)
//     packageZipAndDownload(zipName, type = 'blob') {
//         this.zip.generateAsync({ type }).then(content => {
//             saveAs(content, zipName)
//         })
//     }
// }
// 导出echarts图片，格式转换




const initialValue='15888878787';
const initFlag = false;

const register_width = '130px';




class NewRegisterInfoAdminRegionForm extends React.Component {
    state = {
        confirmDirty: false,
        autoCompleteResult: [],

        widSpan:5,
        projectPlanId:initFlag?initialValue:'',
        name:initFlag?initialValue:'',
        addr:initFlag?initialValue:'',
        floors:initFlag?initialValue:'',
        latitude2:initFlag?initialValue:'',
        longitude2:initFlag?initialValue:'',
        area:initFlag?initialValue:'',
        headCount:initFlag?initialValue:'',
        buildMan:initFlag?initialValue:'',
        buildPhone:initFlag?initialValue:'',
        constructCompany:initFlag?initialValue:'',
        constructMan:initFlag?initialValue:'',
        constructPhone:initFlag?initialValue:'',
        timeFinish:initFlag?initialValue:moment(),
        regionBelong:initFlag?initialValue:'330302',
        buildtype:initFlag?initialValue:'SPORT',
        website:initFlag?initialValue:'',
        inputType:initFlag?initialValue:'',
        dealName:initFlag?initialValue:'',
        dealBuildCode:initFlag?initialValue:'',
        isAgree:initFlag?initialValue:'',
        source:initFlag?initialValue:'',
        collectorName:initFlag?initialValue:'',
        warmType:initFlag?initialValue:'INWARM',
        windType:initFlag?initialValue:'SIGSIG',
        buildCompany:initFlag?initialValue:'',
        buildGlassType:initFlag?initialValue:'NORMAL',
        frameType:initFlag?initialValue:'IRON',
        heatType:initFlag?initialValue:'RAD',
        airSysType:initFlag?initialValue:'RAD',
        informWay:initFlag?[
            // {funcLocate:'1',funcDomain:'1',funcHeads:'1',funcArea:'1'},//功能区位置 功能区
            {funcLocate:'',funcDomain:'',funcHeads:'',funcArea:''},//功能区位置 功能区
        ]:[
            {funcLocate:'',funcDomain:'',funcHeads:'',funcArea:''},//功能区位置 功能区
        ],
        tableData:[],
        checkType:'',
        showForm:false,
        loading:false,
        createProjectPwd:'',
        createPermit:false,
        searchInput:null,
        searchText:'',
        searchedColumn:'',
        testUser:'',
        testPwd:'',
        searchNameText:'',
        filterval:'',
        createCode:'', // 创建时的验证码
        createPwd:'', // 创建时的验证码
        createMail:'', // 创建时的验证码

    };

     handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        // setSearchText(selectedKeys[0]);
        // setSearchedColumn(dataIndex);
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

     handleReset = clearFilters => {
        clearFilters();
        // setSearchText('');
        this.setState({ searchText: '' });
    };

    //  自定义筛选的功能
     getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    // ref={node => {
                    //     this.searchInput = node;
                    // }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            (record[dataIndex]?record[dataIndex]:'')
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            // if (visible) {
            //     setTimeout(() => this.searchInput.select());
            // }
        },
        // render: text =>
        //     searchedColumn === dataIndex ? (
        //         <span
        //             highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        //             // searchWords={[this.state.searchText]}
        //             searchWords={[searchText]}
        //             autoEscape
        //             textToHighlight={text.toString()}
        //         />
        //     ) : (//adfs
        //         text
        //     ),
    });


    columns = [
//     {
//     title:' 序号',
//     dataIndex: 'order',
//     key: 'order',
//     width: '6%',
// },
        {
            title: '建筑编号',
            dataIndex: 'projectPlanId',
            key: 'projectPlanId',
            width:register_width,
            // render: (text, record) => (
            //     <Tooltip placement="top" title={text}>
            //         <span className='tableName'>{text}</span>
            //     </Tooltip>
            // ),
            // sorter: (a, b) => a.kgceEnergy - b.kgceEnergy,
            sortDirections: ['descend'],
            sorter:(a,b)=>a.projectPlanId > b.projectPlanId,

            onFilter: (value, record) => {
                return record.username.toLowerCase().includes(value.toLowerCase());
            },




        },{
            title: '项目名称',
            dataIndex: 'name',
            key: 'name',
            width: register_width,
            ...this.getColumnSearchProps('name')
             // sortDirections: ['descend'],
             // sorter:(a,b)=>a.name > b.name,

        }, {
            title: '项目类型',
            dataIndex: 'buildtype',
            key: 'buildtype',
            width: register_width,
             sortDirections: ['descend'],
            render: (text, record) => (text?buildType[text]:''
            ),
             sorter:(a,b)=>a.buildtype > b.buildtype,
             filters:r.map(y=>({text:buildType[y],value:y}))(CodeArr),
             onFilter: (value, record) => record.buildtype.indexOf(value) === 0,
        }, {
            title: '面积',
            dataIndex: 'area',
            key: 'area',
            width: register_width,
            // filters: r.map((item) => ({text: item, value: item}))(channelNameFilters),
            // onFilter: (value, record) => record.channelName.indexOf(value) === 0,
            sortDirections: ['descend'],
             // defaultSortOrder: 'descend',
             sorter:(a,b)=>a.area > b.area
        },
        {
            title: '人数',
            dataIndex: 'headCount',
            key: 'headCount',
            width: register_width,
            sortDirections: ['descend'],
            sorter:(a,b)=>a.headCount > b.headCount

        },
         {
             title: '最后申请时间',
             dataIndex: 'lastupdate',
             key: 'lastupdate',
             width: register_width,
             // className:'hide',
             render: (text, record) => ((text && text !== '1991-01-01')?moment(text).format('YYYY-MM-DD'):''),
             sortDirections: ['descend'],
             sorter:(a,b)=>moment(a.lastupdate).isBefore(moment(b.lastupdate))
         },
         {
             title: '最后审核时间',
             dataIndex: 'checkDate',
             key: 'checkDate',
             width:register_width,
             render: (text, record) => ((text && text !== '1991-01-01')?moment(text).format('YYYY-MM-DD'):''),
             sortDirections: ['descend'],
             sorter:(a,b)=>moment(a.checkDate).isBefore(moment(b.checkDate))

         },
        {
            title: '区域',
            dataIndex: 'regionBelong',
            key: 'regionBelong',
            render: (text, record) => (text?regionJsonConverter[text]:'-'),
            width: register_width,
            sortDirections: ['descend'],
            sorter:(a,b)=>a.regionBelong > b.regionBelong,
            filters:r.map(y=>({text:regionJsonConverter[y],value:y}))(regionCodeArr),
            onFilter: (value, record) => record.regionBelong.indexOf(value) === 0,
        },
        {
            title: '审核状态',
            dataIndex: 'checkType',
            key: 'checkType',
            width: register_width,
            // render: (text, record) => (text?checkType[text]:''),
            render: (text, record) => (text?(text==='OK'?<span style={{color:'green'}} >{checkType[text]}</span>:<span style={{color:'red'}} >{checkType[text]}</span>):''),
            sortDirections: ['descend'],
            sorter:(a,b)=> a.checkType > b.checkType,
            filters:r.map(y=>({text:checkType[y],value:y}))(checkCodeArr),
            onFilter: (value, record) => record.checkType.indexOf(value) === 0,
        },

        {
            title: '建设单位',
            dataIndex: 'buildCompany',
            key: 'buildCompany',
            width: register_width,
            // className:'hide'
            sortDirections: ['descend'],
            sorter:(a,b)=> a.buildCompany > b.buildCompany
        },
        {
            title: '建设联系人',
            dataIndex: 'buildMan',
            key: 'buildMan',
            width: register_width,
            className:'hide'
        },
        {
            title: '业主确认',
            dataIndex: 'isAgree',
            key: 'isAgree',
            width: register_width,
            // className:'hide'
        },
        {
            title: '建设联系人号码',
            dataIndex: 'buildPhone',
            key: 'buildPhone',
            width: register_width,
            className:'hide'
        },
        {
            title: '地址',
            dataIndex: 'addr',
            key: 'addr',
            width: register_width,
            className:'hide'
        },
        {
            title: '备注',
            dataIndex: 'website',
            key: 'website',
            width: register_width,
            // ellipsis: true,
            className: 'hide',
        },
        {
            title: '经度',
            dataIndex: 'latitude2',
            key: 'latitude2',
            width: register_width,
            // ellipsis: true,
            className: 'hide',
        },
        {
            title: '纬度',
            dataIndex: 'longitude2',
            key: 'longitude2',
            width: register_width,
            // ellipsis: true,
            className: 'hide',
        },
        {
            title: '用户名',
            dataIndex: 'dealName',
            key: 'dealName',
            width: register_width,
            // ellipsis: true,
            className: 'hide',
        },
        {
            title: '建筑编号',
            dataIndex: 'dealBuildCode',
            key: 'dealBuildCode',
            width: register_width,
            // ellipsis: true,
            className: 'hide',
        },
        {
            title: '来源',
            dataIndex: 'source',
            key: 'source',
            width: register_width,
            // ellipsis: true,
            render: (text, record) => ((text==='registerPage')?'注册':((text==='drawCheck')?'审图':text)),
            sortDirections: ['descend'],
            sorter:(a,b)=> a.source > b.source,
            filters:r.map(y=>({text:sourceType[y],value:y}))(sourceCodeArr),
            onFilter: (value, record) => record.source.indexOf(value) === 0,
        },

        {
            title: '状态切换',
            dataIndex: 'opera',
            key: 'opera',
            width: register_width,
            render: (text,record) =>  <Button   onClick={()=>this.switchCheckType(record.projectPlanId,record.checkType)}>切换</Button>
        },

        {
            title: '删除',
            dataIndex: 'opera',
            key: 'opera',
            width: register_width,
            render: (text,record) =>  <Button style={{color:'#f0897b'}} onClick={()=>this.deleteRecord(record.projectPlanId)}>删除</Button>
        },
        {
            title: '显示内容',
            dataIndex: 'show',
            key: 'show',
            width: register_width,
            render: (text,record) =>  <Button   onClick={()=>this.showRow(record)}>显示内容</Button>
        },
        {
            title: '创建项目',
            dataIndex: 'createProject',
            key: 'createProject',
            width: register_width,
            render: (text,record) =>  <Button   onClick={()=>this.createProject(record)}>创建项目</Button>
        },
         {
             title: '接入状态',
             dataIndex: 'steps',
             key: 'steps',
             width: register_width,
             render: (text, record) => (text?stepsArr[Number(text)]:''),
         },
         {
             title: '下发账号',
             dataIndex: 'offerAccount',
             key: 'offerAccount',
             width: register_width,
             render: (text,record) =>  <Button   onClick={()=>this.offerAccount(record)}>下发账号</Button>
         },
         {
             title: '开通统计',
             dataIndex: 'beginSatistics',
             key: 'beginSatistics',
             width: register_width,
             render: (text,record) =>  <Button   onClick={()=>this.beginSatistics(record)}>开通统计</Button>
         },
         {
             title: '运行通过',
             dataIndex: 'passRun',
             key: 'passRun',
             width: register_width,
             render: (text,record) =>  <Button   onClick={()=>this.passRun(record)}>运行通过</Button>
         },
    ];

     // searchInput = useRef(null);

    componentDidMount() {
        // 隐藏对应的格式
        // document.getElementById("navDiv").style.cssText="display:none";
        //初始化创建密码 这里初始化不会运行？
        console.log('componentDidMount-');
        this.getCreateCode();
    }

    getCreateCode=()=>{//获取创建项目时需要的验证码
        if(this.state.createCode && this.state.createPwd && this.state.createMail  ){
            console.log('exist,get createCode from state ---- ');
            return this.state.createCode;
        }else{
            Api.postV3('/getNormalOperaCode', {project:'123',operaType:'create'}).then(res=>{
                console.log('getNormalOperaCode --- ');
                // console.log(res);
                let data = res.data;
                if(data&& data.result ){
                    // console.log('getcontent');
                    // console.log(data.result[0].content);
                    let createArr = data.result;
                    r.map(y=>{
                        if(y.opera_name === 'createProject'){
                            this.setState({createCode:y.content});
                        }
                        if(y.opera_name === 'createPwd'){
                            this.setState({createPwd:y.content});
                        }
                        if(y.opera_name === 'createMail'){
                            this.setState({createMail:y.content});
                        }
                    })(createArr);

                }else{
                    message.info('没有获取操作码CreateProjet',6);
                }
            });
        }
    }

    //  handleSearch = (selectedKeys, confirm, dataIndex) => {
    //     confirm();
    //     // this.state.searchText=(selectedKeys[0]);
    //     // this.state.searchedColumn=dataIndex;
    //      this.setState({searchText:selectedKeys[0]});
    //      this.setState({searchedColumn:dataIndex});
    //
    // };
    //  handleReset = (clearFilters) => {
    //     clearFilters();
    //     // setSearchText('');
    //      this.state.searchText=('');
    //
    //  };

    switchCheckType = (projectPlanId,checkType)=>{


        // alert('switch');
         Api.postV3('/registerInfo2/switch', {
            projectId:'123',
            project:'123',
            projectPlanId:projectPlanId,
            checkType:(checkType==='ING')?'OK':'ING'
        }).then(res=>{message.info('切换成功');
        this.findAllRegisterInfo();
        });


    }
    deleteRecord = (projectPlanId)=>{


        // alert('switch');
         Api.postV3('/registerInfo2/delete', {
            projectId:'123',
            project:'123',
            projectPlanId:projectPlanId,
        }).then(res=>{message.info('删除成功');
        this.findAllRegisterInfo();
        });


    }
    closeForm = ()=>{

        this.setState({showForm:false})

    }

    //使用住建局的发送短信,给建设单位和施工单位
    smsSend10086Both = (code) => {

        if(this.state.buildPhone && this.state.constructPhone){
            // message.info('buildPhone constructPhone',3);
            // message.info(this.state.buildPhone,3);
            // message.info(this.state.constructPhone,3);
            // message.info(this.state.testUser,3);
            // message.info(this.state.testPwd,3);
            // return;
            let phoneArr = [this.state.buildPhone,this.state.constructPhone];
            r.map(y=>{
                // if(true){
                // console.log(x.data.result.mobile);
                let mobile = y;
                let contentNormal = '项目名称: '+this.state.name+', 账号： '+this.state.dealName+', 密码：'+this.state.createPwd+',建筑编号： '
                    +this.state.dealBuildCode+'。 如首次对接系统，务必先在测试环境测试，测试账号：'+this.state.testUser+', 测试密码：'+this.state.testPwd+'。审核所需全套强电图纸发送至'+this.state.createMail;

                // normalContent
                const addRes =  Api.postV3('/sms/normalContent', {project:'123',mobile:sm2Cipher.doEncrypt(mobile, publicKey, cipherMode),content:contentNormal}).then(x=>{
                    console.log('x');
                    console.log(x);

                    if(x&&x.data&&x.data.msg=='ok'){
                        message.info('发送完成 :'+mobile,3);

                        //    倒计时end

                    }else{
                        // x&&x.data&&x.data.code==200
                        if(x&&x.data){
                            message.info(x.data.msg,3);
                        }else{
                            message.info('发送手机验证码错误，请稍后再测试');
                        }
                    }



                });









            })(phoneArr);

        }else{
            message.info('没有可用的手机号');
        }








    };

     showRow = (record)=>{
        // alert(record.projectPlanId);
         this.setState({showForm:true})
         this.setState({loading:true})
        this.findRegisterInfo2(record.projectPlanId,record.name);
         this.setState({loading:false})
    }
    sleep = time => {
        return new Promise(resolve => setTimeout(resolve,time))
    }
    createProject = async (record)=>{
        let _this = this;
        confirm({
            title: '输入创建密码',
            content: ( <div>
                <div className=''>
                    <Input
                        // rows={4}
                        // defaultValue={this.state.resons}
                        placeholder='请输入创建密码:'
                        onChange={(e) => this.setState({ createProjectPwd: e.target.value })}
                    />
                </div>
            </div>),
            onOk() {
                // return;
                // alert(_this.state.createPwd);
                Api.postV3()

                if(_this.state.createProjectPwd === _this.getCreateCode()){
                    _this.setState({ createPermit: true })

                      Api.post('/account/info', { id:  record.dealName,/*authForDraw:true*/}).then(findAccount =>
                        {
                            console.log('查找注册用户是否已经存在');
                            console.log(findAccount);
                            console.log(findAccount.data.result);
                            if(findAccount.data.result && findAccount.data.result.title){
                                message.info('注册用户已创建于能耗平台，请直接登录',5);
                                return;
                            }
                            //不存在，直接进行添加
                            message.info('开始创建项目',2);
                            _this.setState({loading:true});
                            let data = {
                                title:record.name,
                                description:record.website,
                                sector:record.buildtype,
                                source:'SELF',
                                user:record.dealName,
                                passwd:_this.state.createPwd,
                                mobile:record.buildPhone,
                                region:0,// 非区域管理员，为0
                                regionName:regionJsonConverter[record.regionBelong],
                                regionBelongName:regionJsonConverter[record.regionBelong],
                                regionBelong:record.regionBelong,
                                locate:record.addr,
                                longitude2:record.latitude2,// latitude 和 longitude 两个东西弄反了，结果地图上都显示不出来，不知道是不是api模块错了，反正先弄过来吧
                                latitude2:record.longitude2,
                                building:record.dealBuildCode,
                                type:'BASIC',
                                effectiveDays:36500,
                                modules:['dashboard','devices','statistics','presentation','information','building','collector','billingservice','byitem','node'],
                                // authForDraw:true//  这个参数用来 api鉴权，破坏了原有的逻辑体系，但是为了跟审图互通，也没有办法
                            };

                            for(var k in data){
                                if(data[k]===undefined){
                                    message.info(k+'|该参数不存在，请设置！！'+data[k],3);
                                    return;
                                }
                            }
                            if(!/^[0-9]*[.][0-9]{4}/.test(parseFloat(data.latitude2)) || !/^[0-9]*[.][0-9]{4}/.test(parseFloat(data.longitude2))){
                                message.info('经纬度格式不对(4位小数)，请设置'+data.latitude2+'|'+data.longitude2,3);
                                return;
                            }
                            // console.log('add data ------- ');
                            // console.log(data);
                            // return;
                              Api.post('/project/add', data).then(res => {
                                  console.log(res.data.message)
                                  const msg = res.data.message === '' ? '创建成功' : res.data.message;
                                  message.info(msg,3);
                              })



                        }
                    );





                }else{
                    message.info('创建密码错误',4);
                    _this.setState({ createPermit: false })
                }

            },
            onCancel() {
                return;
            },
        })



    }
    offerAccount = async (record)=>{
        if(!record.projectPlanId){
            message.info('没有该项目的信息');
            return;
        }

        const res =  Api.postV3('/registerInfo2/chatRecord', {
            projectId:'123',
            project:'123',
            projectPlanId:record.projectPlanId,
            name:record.name,
            chatRecord:'offerAccount',
            steps:3,
            memo1:'',
            memo2:'',
            date:moment().format('YYYYMMDDHHmmss')
            // name:nameData,
        }).then(x=>{
            if(x&&x.data)
                message.info(x.data);
        })
    }
    beginSatistics = async (record)=>{
        if(!record.projectPlanId){
            message.info('没有该项目的信息');
            return;
        }

        const res =  Api.postV3('/registerInfo2/chatRecord', {
            projectId:'123',
            project:'123',
            projectPlanId:record.projectPlanId,
            name:record.name,
            chatRecord:'beginSatistics',
            steps:5,
            memo1:'',
            memo2:'',
            date:moment().format('YYYYMMDDHHmmss')
            // name:nameData,
        }).then(x=>{
            if(x&&x.data)
                message.info(x.data);
        })
    }
    passRun = async (record)=>{
        if(!record.projectPlanId){
            message.info('没有该项目的信息');
            return;
        }

        const res =  Api.postV3('/registerInfo2/chatRecord', {
            projectId:'123',
            project:'123',
            projectPlanId:record.projectPlanId,
            name:record.name,
            chatRecord:'passRun',
            steps:6,
            memo1:'',
            memo2:'',
            date:moment().format('YYYYMMDDHHmmss')
            // name:nameData,
        }).then(x=>{
            if(x&&x.data)
                message.info(x.data);
        })
    }

//
     findRegisterInfo = ()=>{
         const fromData = this.props.form.getFieldValue('projectPlanId');
         const nameData = this.props.form.getFieldValue('name');
         if(!fromData&&!nameData){
             message.info('请输入预编建筑编号进行查询');
         }

         // alert(fromData);
         console.log('--- to find the plan');
         if(fromData || nameData){
             const res =  Api.postV3('/registerInfo2/get', {
                 projectId:'123',
                 project:'123',
                 projectPlanId:fromData,
                 name:nameData,
             }).then(x=>{
                 if(x.data && x.data.name){
                     // message.info('已经存在项目 ' + x.data.name);
                     let data = x.data;
                     let dataJSON = {
                         projectPlanId:data.projectPlanId,
                         name:data.name,
                         addr:data.addr,
                         area:data.area,
                         floors:data.floors,
                         headCount:data.headCount,
                         isAgree:data.isAgree,
                         buildMan:data.buildMan,
                         buildPhone:data.buildPhone,
                         buildCompany:data.buildCompany,
                         constructCompany:data.constructCompany,
                         constructMan:data.constructMan,
                         constructPhone:data.constructPhone,
                         timeFinish:data.timeFinish?moment(data.timeFinish,'YYYY-MM-DD'):moment(),
                         regionBelong:data.regionBelong,
                         regionBelongExport:data.regionBelong?regionJsonConverter[data.regionBelong]:'',
                         buildtype:data.buildtype,
                         buildtypeExport:data.buildtype?buildType[data.buildtype]:'',
                         website:data.website,
                         inputType:data.inputType,
                         dealName:data.dealName,
                         dealBuildCode:data.dealBuildCode,
                         source:data.source,
                         inputTypeExport:data.inputType===1?'采集器接入':'平台接入',
                         collectorName:data.collectorName,
                         warmType:data.warmType,
                         warmTypeExport:data.warmType?warmType[data.warmType]:'',
                         windType:data.windType,
                         windTypeExport:data.windType?windType[data.windType]:'',
                         buildGlassType:data.buildGlassType,
                         buildGlassTypeExport:data.buildGlassType?buildGlassType[data.buildGlassType]:'',
                         frameType:data.frameType,
                         frameTypeExport:data.frameType?frameType[data.frameType]:'',
                         heatType:data.heatType,
                         heatTypeExport:data.heatType?heatType[data.heatType]:'',
                         airSysType:data.airSysType,
                         airSysTypeExport:data.airSysType?airSysType[data.airSysType]:'',
                         informWay:data.funcLocateDomain?data.funcLocateDomain:[{funcLocate:'',funcDomain:'',funcHeads:'',funcArea:''}],//功能区位置 功能区],
                         informWayExport:{"informWay":[{funcLocate:'5',funcDomain:'5',funcHeads:'',funcArea:''}]},//功能区位置 功能区],
                         checkType:data.checkType?checkType[data.checkType]:''
                     };
                this.setState(dataJSON);
                // this.exportWord(dataJSON);
                 }else{
                     message.info('没有找到对应建筑编号的项目');
                 }
                 console.log(x)});
         }
        // saveAs(wenzhoushiPdf, "exportDocx.pdf");
    }
    createRegisterInfo = ()=>{
         const fromData = this.props.form.getFieldValue('projectPlanId');
         const nameData = this.props.form.getFieldValue('name');
         if(!fromData&&!nameData){
             message.info('要有预编建筑编号才可以生成！！');
         }

         // alert(fromData);
         console.log('--- to create the file');
         if(fromData || nameData){
             const res =  Api.postV3('/exportRegisterPdf/create', {
                 projectId:'123',
                 project:'123',
                 projectPlanId:fromData,
                 name:nameData,
             }).then(x=>{
                 if(x.data && x.data.code===200){
                     // message.info('已经存在项目 ' + x.data.name);
                     // let data = x.data;
                     message.info('后台已生成word，等待服务器定时任务转化为pdf');
                // this.exportWord(dataJSON);
                 }else{
                     message.info('没有找到对应建筑编号的项目');

                 }
                 console.log(x)});
         }
        // saveAs(wenzhoushiPdf, "exportDocx.pdf");
    }
     findRegisterInfo2 = (fromData,nameData)=>{
         // const fromData = this.props.form.getFieldValue('projectPlanId');
         // const nameData = this.props.form.getFieldValue('name');
         if(!fromData){
             message.info('请输入预编建筑编号进行查询');
         }

         // alert(fromData);
         console.log('--- to find the plan');
         if(fromData || nameData){
             const res =  Api.postV3('/registerInfo2/get', {
                 projectId:'123',
                 project:'123',
                 projectPlanId:fromData,
                 name:nameData,
             }).then(x=>{
                 if(x.data && x.data.name){
                     // message.info('已经存在项目 ' + x.data.name);
                     let data = x.data;
                     let dataJSON = {
                         projectPlanId:data.projectPlanId,
                         name:data.name,
                         addr:data.addr,
                         area:data.area,
                         floors:data.floors,
                         latitude2:data.latitude2,
                         longitude2:data.longitude2,
                         upFloors:data.upFloors,
                         device:data.device,
                         collector:data.collector,
                         downFloors:data.downFloors,
                         headCount:data.headCount,
                         isAgree:data.isAgree,
                         buildMan:data.buildMan,
                         buildPhone:data.buildPhone,
                         buildCompany:data.buildCompany,
                         constructCompany:data.constructCompany,
                         constructMan:data.constructMan,
                         constructPhone:data.constructPhone,
                         timeFinish:data.timeFinish?moment(data.timeFinish,'YYYY-MM-DD'):moment(),
                         regionBelong:data.regionBelong,
                         regionBelongExport:data.regionBelong?regionJsonConverter[data.regionBelong]:'',
                         buildtype:data.buildtype,
                         buildtypeExport:data.buildtype?buildType[data.buildtype]:'',
                         website:data.website,
                         inputType:data.inputType,
                         dealName:data.dealName,
                         dealBuildCode:data.dealBuildCode,
                         source:data.source,
                         inputTypeExport:data.inputType===1?'采集器接入':'平台接入',
                         collectorName:data.collectorName,
                         warmType:data.warmType,
                         warmTypeExport:data.warmType?warmType[data.warmType]:'',
                         windType:data.windType,
                         windTypeExport:data.windType?windType[data.windType]:'',
                         buildGlassType:data.buildGlassType,
                         buildGlassTypeExport:data.buildGlassType?buildGlassType[data.buildGlassType]:'',
                         frameType:data.frameType,
                         frameTypeExport:data.frameType?frameType[data.frameType]:'',
                         heatType:data.heatType,
                         heatTypeExport:data.heatType?heatType[data.heatType]:'',
                         airSysType:data.airSysType,
                         airSysTypeExport:data.airSysType?airSysType[data.airSysType]:'',
                         informWay:data.funcLocateDomain?data.funcLocateDomain:[{funcLocate:'',funcDomain:'',funcHeads:'',funcArea:''}],//功能区位置 功能区],
                         informWayExport:{"informWay":[{funcLocate:'5',funcDomain:'5',funcHeads:'',funcArea:''}]},//功能区位置 功能区],
                         checkType:data.checkType?checkType[data.checkType]:''

                     };
                this.setState(dataJSON);
                console.log(dataJSON);
                // this.exportWord(dataJSON);
                 }else{
                     message.info('没有找到对应建筑编号的项目');

                 }
                 console.log(x)});
         }
        // saveAs(wenzhoushiPdf, "exportDocx.pdf");
    }

    //获取所有的申请数据
    // 从数据库中找到对应的数据然后展示
    findAllRegisterInfo = ()=>{


        // alert(fromData);
        console.log('--- to find the plan');
            const res =  Api.postV3('/registerInfo2/getAll', {
                projectId:'123',
                project:'123',
                pv:0,
            }).then(x=>{
                if(x.data){
                    // message.info('已经存在项目 ' );
                    let data = x.data;
                    if(isRegion()){
                        let region = cookie.load('region');
                        if(region !='330300'){
                            data=r.filter(y=> y.regionBelong=== region)(data);
                        }

                    }else{

                    }
                    r.map(y=>{
                        if(y){
                            y.area=((y.area)?Number(y.area):0);
                            y.checkType=((y.checkType)?(y.checkType):'EMPTY');
                            y.source=((y.source)?(y.source):'');
                            y.buildCompany=((y.buildCompany)?(y.buildCompany):'');
                            y.regionBelong=((y.regionBelong)?(y.regionBelong):'');
                            y.checkDate=((y.checkDate)?(y.checkDate):'1991-01-01');
                            y.lastupdate=((y.lastupdate)?(y.lastupdate):'1991-01-01');
                            y.headCount=((y.headCount)?(y.headCount):'');
                            y.isAgree=((y.isAgree)?(y.isAgree):'');
                            y.area=((y.area)?(y.area):'');
                            y.buildtype=((y.buildtype)?(y.buildtype):'');
                            y.name=((y.name)?(y.name):'');
                            y.projectPlanId=((y.projectPlanId)?(y.projectPlanId):'');
                        }


                    })(data);
                    console.log('data=== ');
                    console.log(data);
                    this.setState({tableData:data})
                    // this.exportWord(dataJSON);
                }else{
                    message.info('没有找到项目');

                }
                console.log(x)});

    }



    handleSubmit = (e) => {


        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            console.log(err)
            console.log(values)

            if (!err) {
                confirm({
                    title: '请确认填写的信息准确无误，是否提交？',
                    content: <div><p>&nbsp;&nbsp;&nbsp;&nbsp;本表所填资料完全属实，且保证建筑能耗监测系统计量数据正常、可靠、稳定上传，并承担建筑能耗计量装置的管理和维护工作。
                        <br/>&nbsp;&nbsp;&nbsp;&nbsp;若未能保证建筑能耗计量装置运行正常导致平台数据异常、缺失等情况的，一切法律责任与后果由我公司自行承担。  </p> </div>,
                    onOk() {
                        // return;
                        console.log('Received values of form: ', values);
                        const {	 name,
                            projectPlanId,
                            addr,
                            floors,
                            upFloors,
                            latitude2,
                            longitude2,
                            downFloors,
                            area,
                            headCount,
                            isAgree,
                            buildCompany,
                            buildMan,
                            buildPhone,
                            constructCompany,
                            constructMan,
                            constructPhone,
                            timeFinish,
                            regionBelong,
                            buildtype,
                            website,
                            inputType,
                            dealName,
                            dealBuildCode,
                            source,
                            collectorName,
                            device,
                            collector,
                            warmType,
                            windType,
                            buildGlassType,
                            frameType,
                            heatType,
                            airSysType,
                            funcLocateDomain,} = values;
                        let regionBelongName = regionJsonConverter[regionBelong];
                        // const modules = joinAllModules(web, admin, analysis, extra)
                        const  testFunc = /^(funcLocate_)\d$/;
                        var funcLocateDomainArr=[];
                        var funcLocate={};
                        var funcDomain={};
                        var funcHeads={};
                        var funcArea={};
                        for(var key in values){
                            if(testFunc.test(key)){
                                //检查funcLocate和funcDomain是否存在空的情况
                                var index = key.lastIndexOf('_');
                                var num = key.substr(index+1,key.length);
                                if(num && values[key] && values['funcDomain_'+num] && values['funcHeads_'+num] && values['funcArea_'+num]){
                                    // funcLocate[key]=allValues[key];
                                    // funcDomain['funcDomain_'+num]=allValues['funcDomain_'+num];
                                    // funcHeads['funcHeads_'+num]=allValues['funcHeads_'+num];
                                    // funcArea['funcArea_'+num]=allValues['funcArea_'+num];
                                    funcLocateDomainArr.push({funcLocate:values[key],
                                        funcDomain:values['funcDomain_'+num],
                                        funcHeads:values['funcHeads_'+num],
                                        funcArea:values['funcArea_'+num],

                                    });
                                }
                            }
                        }



                        return Api.postV3('/registerInfo2/add', {
                            projectId:'123',
                            project:'123',
                            projectPlanId:projectPlanId,
                            name,
                            addr,
                            floors,
                            upFloors,
                            downFloors,
                            latitude2,
                            longitude2,
                            area,
                            headCount,
                            isAgree,
                            buildCompany,
                            buildMan,
                            buildPhone,
                            constructCompany,
                            constructMan,
                            constructPhone,
                            timeFinish,
                            regionBelong,
                            buildtype,
                            website,
                            inputType,
                            dealName,
                            dealBuildCode,
                            source,
                            collectorName,
                            device,
                            collector,
                            warmType,
                            windType,
                            buildGlassType,
                            frameType,
                            heatType,
                            airSysType,
                            funcLocateDomain:funcLocateDomainArr,
                            regionBelongName,
                            noUpdateCreateTime:true
                        }).then(res=>{message.info('创建成功')});
                        // console.log(res.data)
                        // const msg = res.data === 'ok' ? '创建成功' : res.data;
                        // message.info(msg);
                        // if (msg === '创建成功') {
                        //     this.props.history.push('/Project')
                        // }
                    },
                    onCancel() {
                        return;
                    },
                });


            }
        });
    }


    render() {
        const {getFieldDecorator, getFieldValue} = this.props.form;



        // console.log('modules', r.toPairs(r.propOr({})('web')(projectModules)))
        return (
            <div className="newRegisterInfoRegion">
                <h2 style={{color:'#abb1ac'}}>温州市民用建筑能耗信息管理系统(区域审核)</h2>
                <Spin spinning={this.state.loading}/>
                <Form onSubmit={this.handleSubmit} className={this.state.showForm?'registerAdminRegionOpen':'registerAdminRegionClose'}>

                    <Row>
                        <Col span={10}>
                            <div className="border-nice" style={{height:'230px'}}>
                                <h2 style={{color:'#a65ac8'}}>建设单位信息</h2>

                                <Form.Item {...formItemLayout}
                                           label='建设单位'
                                >
                                    {getFieldDecorator('buildCompany', {
                                        rules: [{required: true, message: '建设单位不能为空', whitespace: true},],
                                        initialValue: this.state.buildCompany,
                                    })(
                                        <Input  placeholder="请输入建设单位"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label='建设单位联系人'
                                >
                                    {getFieldDecorator('buildMan', {
                                        rules: [{required: true, message: '建设单位联系人不能为空', whitespace: true},],
                                        initialValue: this.state.buildMan,
                                    })(
                                        <Input  placeholder="请输入建设单位联系人"/>
                                    )}
                                </Form.Item>



                                <Form.Item {...formItemLayout}
                                           label="建设单位联系电话"
                                >
                                    {getFieldDecorator('buildPhone', {
                                        rules: [{required: true, message: '联系电话不能为空'}, {
                                            // 由于工信部放号段不定时，所以建议使用泛解析
                                            pattern: /^([1][3,4,5,6,7,8,9])\d{9}$/, message: '请输入正确的手机号'
                                        }],
                                        initialValue: this.state.buildPhone,
                                    })(
                                        <Input style={{width: '100%'}}/>
                                    )}
                                </Form.Item>
                            </div>

                            <div className="border-nice"  style={{height:'660px'}}>
                                <h2 style={{color:'#a65ac8'}}>项目信息</h2>
                                <Form.Item {...formItemLayout}
                                           label="项目名称"
                                >
                                    {getFieldDecorator('name', {
                                        rules: [{
                                            required: true, message: '项目名称不能为空',
                                        }],
                                        initialValue: this.state.name,
                                    })(
                                        <Input placeholder="请输入项目名称"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="预编项目编号"
                                >
                                    {getFieldDecorator('projectPlanId', {
                                        rules: [{
                                            required: true, message: '项目编号不能为空',
                                        }],
                                        // initialValue: Math.floor(100000000+10000000000000*Math.random()),
                                        initialValue: this.state.projectPlanId,
                                    })(
                                        <Input placeholder="请输入8位以上编号"/>
                                    )}
                                </Form.Item>

                                <Form.Item {...formItemLayout}
                                           label="项目地址"
                                >
                                    {getFieldDecorator('addr', {
                                        rules: [{
                                            required: true, message: '项目地址不能为空',
                                        }],initialValue: this.state.addr,
                                    })(
                                        <Input placeholder="请输入项目地址"/>
                                    )}
                                </Form.Item>
                                {/*<Form.Item {...formItemLayout}*/}
                                {/*label="楼层数(地上+地下)"*/}
                                {/*>*/}
                                {/*{getFieldDecorator('floors', {*/}
                                {/*rules: [{*/}
                                {/*required: true,*/}
                                {/*message:'只能输入数字',*/}
                                {/*pattern: /^[0-9]+$/*/}
                                {/*}],initialValue: this.state.floors,*/}
                                {/*})(*/}
                                {/*<Input placeholder="请输入建筑层数地下"/>*/}
                                {/*)}*/}
                                {/*</Form.Item>*/}
                                <Form.Item {...formItemLayout}
                                           label="地上楼层数"
                                >
                                    {getFieldDecorator('upFloors', {
                                        rules: [{
                                            required: true,
                                            message:'只能输入数字',
                                            pattern: /^[0-9]+$/
                                        }],initialValue: this.state.upFloors,
                                    })(
                                        <Input placeholder="请输入地上楼层数"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="地下楼层数"
                                >
                                    {getFieldDecorator('downFloors', {
                                        rules: [{
                                            required: true,
                                            message:'只能输入数字',
                                            pattern: /^[0-9]+$/
                                        }],initialValue: this.state.downFloors,
                                    })(
                                        <Input placeholder="请输入地下楼层数"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="总建筑面积(m²)"
                                >
                                    {getFieldDecorator('area', {
                                        rules: [{
                                            required: true,
                                            message:'输入数字，精确小数点2位',
                                            pattern: /^[0-9]*[.][0-9]{2}$/
                                        }],initialValue: this.state.area,
                                    })(
                                        <Input placeholder="XXXX.XX"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="使用人数"
                                >
                                    {getFieldDecorator('headCount', {
                                        rules: [{
                                            // required: true,
                                            message:'只能输入数字',
                                            pattern: /^[0-9]+$/
                                        }],
                                        initialValue: this.state.headCount,
                                    })(
                                        <Input placeholder="总建筑人数"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="竣工时间"
                                >
                                    {getFieldDecorator('timeFinish', {
                                        initialValue: this.state.timeFinish,
                                        rules: [{  required: true, message: '请选择时间!' }]
                                    })(
                                        <DatePicker  style={{width:'100%'}} />
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="经度(选填)"
                                >
                                    {getFieldDecorator('latitude2', {
                                        initialValue: this.state.latitude2,
                                        rules: [{   message: '请输入经度!' },{pattern: /^[0-9]*[.][0-9]{4}/, message: '经度不能为空，小数保留4位', whitespace: true}]
                                    })(
                                        <Input   style={{width:'60%'}} placeholder="XX.XXXX"/>
                                    )}
                                    <Button   style={{width:'40%', color:'#33bdb7'}} target = "_blank" href = "http://api.map.baidu.com/lbsapi/getpoint/index.html" >取经纬度</Button>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="纬度(选填)"
                                >
                                    {getFieldDecorator('longitude2', {
                                        initialValue: this.state.longitude2,
                                        rules: [{  message: '请输入纬度!' },{pattern: /^[0-9]*[.][0-9]{4}/, message: '经度不能为空，小数保留4位', whitespace: true}]
                                    })(
                                        <Input placeholder="XX.XXXX"/>
                                    )}
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={14}>
                            <div  className="border-nice"  style={{height:'230px'}}>

                                <h2 style={{color:'#a65ac8'}}>施工单位信息</h2>
                                <Form.Item {...formItemLayout}
                                           label='施工单位'
                                >
                                    {getFieldDecorator('constructCompany', {
                                        rules: [{required: true, message: '施工单位不能为空', whitespace: true},],
                                        initialValue: this.state.constructCompany,
                                    })(
                                        <Input  placeholder="请输入施工单位"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label='施工单位联系人'
                                >
                                    {getFieldDecorator('constructMan', {
                                        rules: [{required: true, message: '施工单位联系人不能为空', whitespace: true},],
                                        initialValue: this.state.constructMan,
                                    })(
                                        <Input  placeholder="请输入施工单位联系人"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="施工单位联系电话"
                                >
                                    {getFieldDecorator('constructPhone', {
                                        rules: [{required: true, message: '联系电话不能为空'}, {
                                            // 由于工信部放号段不定时，所以建议使用泛解析
                                            pattern: /^([1][3,4,5,6,7,8,9])\d{9}$/, message: '请输入正确的手机号'
                                        }],
                                        initialValue: this.state.constructPhone,
                                    })(
                                        <Input style={{width: '100%'}}/>
                                    )}
                                </Form.Item>
                            </div>

                            <div className="border-nice"  style={{height:'540px'}}>
                                {/*<h2>模块管理</h2>*/}
                                <h2 style={{color:'#a65ac8'}}>系统信息</h2>

                                <Form.Item {...formItemLayout}
                                           label="系统平台信息1(仪表数)"
                                >
                                    {getFieldDecorator('device', {
                                        initialValue: this.state.device,
                                        rules: [{  required: true, message: '请输入项目设备数量!' }]
                                    })(
                                        <Input placeholder="项目设备数量"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="系统平台信息2(终端数)"
                                >
                                    {getFieldDecorator('collector', {
                                        initialValue: this.state.collector,
                                        rules: [{  required: true, message: '请输入项目终端数量!' }]
                                    })(
                                        <Input placeholder="项目终端数量"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="归属区域"
                                >
                                    {getFieldDecorator('regionBelong', {
                                        rules: [{
                                            required: true
                                        }],
                                        initialValue: this.state.regionBelong,

                                    })(
                                        <Select>
                                            {/*<Option value={0}>不可查看</Option>*/}
                                            {/*<Option value={330300}>温州市</Option>*/}
                                            <Option value={"330301"}>市辖区</Option>
                                            <Option value={"330302"}>鹿城区</Option>
                                            <Option value={"330303"}>龙湾区</Option>
                                            <Option value={"330304"}>瓯海区</Option>
                                            <Option value={"330305"}>洞头区</Option>
                                            <Option value={"330324"}>永嘉县</Option>
                                            <Option value={"330326"}>平阳县</Option>
                                            <Option value={"330327"}>苍南县</Option>
                                            <Option value={"330328"}>文成县</Option>
                                            <Option value={"330329"}>泰顺县</Option>
                                            <Option value={"330371"}>温州高新区(经开区)</Option>
                                            <Option value={"330381"}>瑞安市</Option>
                                            <Option value={"330382"}>乐清市</Option>
                                            <Option value={"330354"}>温州生态园</Option>
                                            <Option value={"330355"}>瓯江口</Option>
                                            <Option value={"330383"}>龙港市</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="建筑类型"
                                >
                                    {getFieldDecorator('buildtype', {
                                        rules: [{
                                            required: true, message: '建筑类型不能为空',
                                        }],
                                        // initialValue: 'SPORT',
                                        initialValue: this.state.buildtype,
                                    })(
                                        <Select placeholder="请选择建筑类型">
                                            {r.map(([v, k]) => <Option value={v} key={v}>{k}</Option>)(r.toPairs(buildType))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="项目描述"
                                >
                                    {getFieldDecorator('website', {
                                        rules: [{required: true, message: '项目描述不能为空'}],
                                        initialValue: this.state.website,
                                    })(
                                        <Input.TextArea/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="接入方式"
                                >
                                    {getFieldDecorator('inputType', {
                                        rules: [{
                                            required: true
                                        }],
                                        initialValue: this.state.inputType,

                                    })(
                                        <Select>
                                            <Option value={1}>采集器接入</Option>
                                            <Option value={2}>平台接入</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="采集器类型(采集器接入选填)"
                                >
                                    {getFieldDecorator('collectorName', {
                                        initialValue: this.state.collectorName,
                                    })(
                                        <Input placeholder="请输入采集器类型" />
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="拟用用户名"
                                >
                                    {getFieldDecorator('dealName', {
                                        initialValue: this.state.dealName,
                                    })(
                                        <Input placeholder="请输入拟用用户名" />
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="拟用建筑编号"
                                >
                                    {getFieldDecorator('dealBuildCode', {
                                        initialValue: this.state.dealBuildCode,
                                    })(
                                        <Input placeholder="拟用建筑编号" />
                                    )}
                                </Form.Item>

                                <Form.Item {...formItemLayout}
                                           label="来源"
                                >
                                    {getFieldDecorator('source', {
                                        initialValue: this.state.source,
                                    })(
                                        <Select placeholder="来源">
                                            {r.map(([v, k]) => <Option value={v} key={v}>{k}</Option>)(r.toPairs(sourceType))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="已确认"
                                >
                                    {getFieldDecorator('isAgree', {
                                        initialValue: this.state.isAgree,
                                        rules: [{required: false,pattern: /^(已确认)$/, message: '请输入 已确认 或 保留空值', whitespace: true},],
                                    })(
                                        <Input placeholder="请输入 已确认 或 保留空值" />
                                    )}
                                </Form.Item>


                            </div>

                        </Col>
                    </Row>
                    <Form.Item {...tailFormItemLayout}>
                        {/*<Button type="primary" htmlType="submit" className='newProjectFormButton'>确认</Button>*/}
                        {/*<Button className='newProjectFormButton'><Link to='/Project'>取消</Link></Button>*/}
                        <div><span style={this.state.checkStyle}>{(this.state.checkType)?checkType[this.state.checkType]:''}</span></div>
                        <Button type="primary" htmlType="submit" className='newProjectFormButton' >确认</Button>
                        {/*<Button className='newProjectFormButton'>取消</Button>*/}
                        <Button className='newProjectFormButton' onClick={this.findRegisterInfo}>查询</Button>
                        <Button className='newProjectFormButton' onClick={this.createRegisterInfo}>生成报告</Button>
                        {/*<Button className='newProjectFormButton' onClick={this.exportInfo}>导出</Button>*/}
                        {/*<Button className='newProjectFormButton' onClick={downloadProtocol} style={{width:'10%'}}>下载接入标准</Button>*/}
                        <Button className='newProjectFormButton' onClick={this.closeForm}>关闭</Button>
                        <Button className='newProjectFormButton' onClick={this.smsSend10086Both}>发送短信给建设单位和施工单位</Button>
                    </Form.Item>
                </Form>
                <Input
                    placeholder='测试账号,不保存，仅当前短信使用'
                    onChange={(e) => this.setState({testUser:e.target.value })}
                /><Input
                placeholder='测试密码,不保存，仅当前短信使用'
                onChange={(e) => this.setState({testPwd:e.target.value })}
            />




                <Button className='newProjectFormButton' onClick={this.findAllRegisterInfo}>查询全部</Button>

                <Table
                    columns={this.columns}
                    dataSource={this.state.tableData}
                    scroll={{y: 710}}
                    pagination={{defaultPageSize: 10}}
                    // loading={loading}
                    // onRow={(record)=>{
                    //     return{
                    //         onClick:event=>{
                    //             this.showRow(record);
                    //         }
                    //     }
                    // }}
                    rowKey={record => record.projectPlanId}
                    className='reportRightTableTabAvg'/>



            </div>
        );
    }
}

const NewRegisterInfoAdminRegion = Form.create({name: 'newRegisterInfoAdminRegion'})(NewRegisterInfoAdminRegionForm);


export default NewRegisterInfoAdminRegion;
