import axios from 'axios';
import qs from 'qs';
import cookie from 'react-cookies';

let Api = {
    get: '',
    post: '',
    postDraw:''
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
        'X-CustomHeader': 'energy'
    },
    withCredentials: true
});

// 审图系统请求
const instanceDraw = axios.create({
    baseURL: '',
    headers: {
        // 'X-CustomHeader': 'energy',
        'Access-Control-Allow-Origin':'*',
    },
    changeOrigin:true,
    withCredentials: true
});
// // 10086 短信接入
// const instanceSms10086 = axios.create({
//     baseURL: process.env.REACT_APP_API_Sms10086_HOST,
//     // headers: {
//     //     'X-CustomHeader': 'energy',
//     //     'Access-Control-Allow-Origin':'*',
//     // },
//     changeOrigin:true,
//     withCredentials: true
// });

//下载的url放在这里
//REACT_APP_API_INSTRUCTION_HOST=https://www.wznhjc.cn/jzjn_files/wznhjc_instruction_v1.pdf
// REACT_APP_API_SAMPLE_HOST=https://www.wznhjc.cn/jzjn_files/sample2021.pdf
// REACT_APP_API_REGISTER_HOST=https://www.wznhjc.cn/register
// REACT_APP_API_REGISTERPV_HOST=https://www.wznhjc.cn/registerPV
Api.INSTRUCTION_Url=process.env.REACT_APP_API_INSTRUCTION_HOST
Api.SAMPLE_Url=process.env.REACT_APP_API_SAMPLE_HOST
Api.REGISTER_Url=process.env.REACT_APP_API_REGISTER_HOST
Api.REGISTERPV_Url=process.env.REACT_APP_API_REGISTERPV_HOST


Api.SMS_APPID=process.env.REACT_APP_API_SMS_APPID
Api.SMS_SECRETKEY=process.env.REACT_APP_API_SMS_SECRETKEY
Api.SMS_SIGN=process.env.REACT_APP_API_SMS_SIGN

Api.get = (api, data) => instance.get(api, data)

Api.post = (api, data, config) => instance.post(`/api${api}`, data, config)
Api.postDraw = (api, data, config) => instanceDraw.post(`${api}`, data, config)
// Api.postSms10086 = (api, data, config) => instanceSms10086.post(`${api}`, data, config)
//EnergySms10086
Api.postSms10086 = (api, data, config) =>  instance.post(`/sms/norsubmit${api}`, data)

Api.postV3 = (api, data) => instance.post(`/api/v3${api}`, data)
Api.getV3 = path => instance.get(`/api/v3${path}`)
Api.postV_drawing = (api, data) => instance.post(`/EnergyMonitorService/api/EnergyMonitor${api}`, data)

//only support this project so far
Api.projectId = cookie.load('projectId')
Api.projectName = cookie.load('projectName')
Api.projectCreateTime = cookie.load('projectCreateTime')
//保存api的区域列表
Api.region = cookie.load('region')
Api.regionProjects = cookie.load('regionProjects')
export default Api;